
.account-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .del-wrapper {
        margin-bottom: 20px;
        display: flex;

        .del-btn {
            cursor: pointer;
            transition: all .3s;

            i {
                margin-right: 6px;
            }

            &:hover {
                color: #5864e4;
            }
        }

        .del-number {
            margin-left: 20px;
        }
    }

    .account-number {
        display: flex;
        align-items: center;

        img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }

        .scope-title {
            max-width: 128px;
            margin-left: 10px;
            color: #333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            cursor: pointer;
            font-size: 14px;
        }
    }

    .down {
        & i {
            font-size: 14px;
            color: #42D7AB;
            -ms-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            padding-right: 10px;
            display: inline-block;
            transform: rotate(180deg);
        }
    }

    .up {
        & i {
            font-size: 14px;
            color: #FF0000;
            padding-left: 10px;
        }
    }

    .operation {
        .topping {
            span {
                color: #4659f2;

                &:hover {
                    color: #463bdc;
                }
            }
        }

        .del {
            span {
                color: #f56c6c;

                &:hover {
                    color: #ff0000;
                }
            }
        }
    }

    .under-passed {
        color: #1BB285;
    }

    .under-err {
        color: #f56c6c;
    }

    .script-content-list {
        .script-content-tab {
            ::v-deep .el-tabs__item {
                height: 45px;
                line-height: 45px;
                font-size: 16px;
                font-weight: 400;
                border: none;
                color: #16113F;
            }

            ::v-deep .el-tabs__active-bar {
                background: #9ACC5B;
            }

            ::v-deep .el-tabs__active-bar:before {
                position: absolute;
                top: -13px;
                left: 40%;
                content: '';
                width: 0;
                height: 0;
                border-style: dashed dashed solid dashed;
                border-width: 7px;
                border-color: transparent transparent #9ACC5B transparent;
            }

            /*::v-deep.el-tabs__item{*/
            /*    padding: 0;*/
            /*    margin-right:20px;*/
            /*    box-sizing: border-box;*/
            /*}*/
        }

        .dy-main {
            display: flex;
            flex-direction: column;
            height: calc(100vh - 286px);

            ::v-deep .el-table {
                display: flex;
                flex-direction: column;
            }

            ::v-deep .el-table__footer-wrapper, ::v-deep .el-table__header-wrapper {
                overflow: initial;
            }

            ::v-deep .el-table__body-wrapper {
                overflow: auto;
            }
        }
    }
}

